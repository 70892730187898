import { HOUSE_TYPE_ID } from '@config'
import { houseSearchType } from '@routerConfig'
import dayjs from 'dayjs'
import 'dayjs/locale/en-nz'
import duration from 'dayjs/plugin/duration'
import isYesterday from 'dayjs/plugin/isYesterday'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { Base64 } from 'js-base64'
import { camelCase, capitalize, each, filter, first, get, isArray, isNull, isPlainObject, isUndefined, join, map, mapValues, replace, size, sortBy, split, toString } from 'lodash-es'
import numeral from 'numeral'
import { isBusinessesForSaleData, isCommercialForLeaseData, isCommercialForSaleData, isEstimateData, isNewhomeData, isOfflineData, isProjectData, isRentData, isRuralData } from './house'
import { notFound } from 'next/navigation'
import { twMerge } from 'tailwind-merge'
import classNames from 'classnames'
dayjs.locale('en-nz')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(isYesterday)

/**
 * get dayjs
 * @param {number} timeStamp 时间戳
 * @returns date str
 */
export function getDayjs(timeStamp) {
  return dayjs.unix(parseInt(timeStamp)).tz('Pacific/Auckland')
}

/**
 * 格式化时间 https://dayjs.gitee.io/docs/zh-CN/display/format
 * @param {number} timeStamp 时间戳
 * @param {string} format format
 * @returns date str
 */
export function getTime(timeStamp, format = 'LT, L') {
  return getDayjs(timeStamp).format(format)
}

/**
 * 时间拟人化
 * @param {number} time 时间
 * @param {string} unit 时间单位
 * @returns a minute
 */
export function timeHumanize(time, unit = 'second') {
  return dayjs.duration(time, unit).humanize()
}

/**
 * 从现在开始的相对时间字符串
 * @param {number} timeStamp 时间戳
 * @returns string
 */
export function timeFromNow(timeStamp, format) {
  const now = Math.floor(Date.now() / 1000)
  if (getDayjs(timeStamp).isYesterday()) {
    return 'yesterday'
  }
  if (getTime(now, 'YYYY-MM') === getTime(timeStamp, 'YYYY-MM')) {
    return getDayjs(timeStamp).fromNow()
  }
  return getTime(timeStamp, format)
}

// 比较两个时间的差值
export function timeDiff(timeStamp1, timeStamp2, format = 'HH:mm') {
  const date1 = dayjs.unix(parseInt(timeStamp1)).tz('Pacific/Auckland')
  const date2 = dayjs.unix(parseInt(timeStamp2)).tz('Pacific/Auckland')
  const diff = date1.diff(date2)
  const diffDuration = dayjs.duration(diff)
  return diffDuration.format(format)
}

/**
 * 删除图片loading背景
 * @param {Event} event
 */
export function removeLoading(event) {
  const el = (event.target || event)
  el.removeAttribute('imgbg')
  el.removeAttribute('imgsize')
}

/**
 * style转object
 * @param {string} str style string
 * @returns 
 */
export function styleStringToObject(str) {
  let res = {}
  each(split(str || '', ';'), item => {
    let entry = split(item, ':')
    res[hyphensToCamelCase(entry[0])] = entry[1]
  })
  return res
}

/**
 * 短横线转驼峰
 * @param {string} str
 * @returns string
 */
export function hyphensToCamelCase(str) {
  return `${str}`.replace(/-([a-z])/g, group => group[1].toUpperCase())
}

/**
 * 生成slug
 * @param  {...any} args 
 * @returns slug
 */
export function getSlug(...args) {
  let slugs = map(args, item => _(item))
  return join(slugs, '-').replace(/-{2,}/, '-')

  function _(str) {
    if (isArray(str)) {
      return join(map(str), item => _(item))
    } else {
      return `${str}`.toLocaleLowerCase().replace(/[^a-z0-9\s]/gi, ' ').replace(/\s+/g, '-').replace(/<\/?[^>]+>/g, '')
    }
  }
}

/**
 * slug转id
 * @param {string} slug slug
 * @returns id
 */
export function slugToId(slug) {
  return slug ? `${slug}`.split('-').pop().trim() : slug
}

/**
 * slug转名字 首字母大写
 * @param {string} slug slug
 * @returns slug name
 */
export function slugToName(slug) {
  let str = `${slug}`.replace(/-\d+$/, '')
  return join(map(str.split('-'), item => capitalize(item)), ' ')
}

/**
 * 是否详情的slug
 * @param {string} slug 
 * @returns boolean
 */
export function isDetailSlug(slug) {
  return /\d+$/.test(slug)
}

/**
 * 格式化数字 http://numeraljs.com/
 * @param {string} val 
 * @param {string} format 
 * @returns number
 */
export function mumFormat(val, format) {
  return numeral(val).format(format)
}

/**
 * 数字转价格
 * @param {string} val 
 * @returns price
 */
export function toPrice(val, symbol = '$', format = '0,0.[00]a') {
  if (!val) return val
  return mumFormat(val, `${symbol}${format}`).toLocaleUpperCase()
}

/**
 * 数字转价格
 * @param {string} val 
 * @returns price
 */
export function toPrice2(val) {
  if (!val) return val
  // https://7thave.atlassian.net/browse/HT-2480?focusedCommentId=14558
  // 大于100万显示取两位小数，否则不显示小数
  let format = val >= 1000000 ? `0,0.[00]a` : `0,0a`
  return mumFormat(val, format).toLocaleUpperCase()
}

/**
 * 可取消的promise
 * @example `
 * var obj = getPromiseWithAbort(promise)
 * obj.promise.then(res=>{console.log(res)})
 * //如果要取消
 * obj.abort('取消执行')
 * `
 * @param {promise} p promise
 * @returns promise
 */
export function getPromiseWithAbort(p) {
  let obj = {}
  let p1 = new Promise(function (resolve, reject) {
    obj.abort = reject
  })
  obj.promise = Promise.race([p, p1])
  return obj
}

/**
 * 顺序执行promise
 * @param {array} promises 一个promise数组
 * @returns result
 */
export async function executePromisesInOrder(promises) {
  return await promises.reduce(async (previousResultsPromise, currentPromise) => {
    const previousResults = await previousResultsPromise
    const currentResult = await currentPromise()
    return [...previousResults, currentResult]
  }, Promise.resolve([]))
}

/**
 * 事件订阅
 * @param {event} eventType 
 * @param {function} callback 
 * @param {HTMLElement} target 
 */
export function subscribeUI(eventType, callback, target = window) {
  if (target && eventType) {
    target.addEventListener(eventType, callback)
  }
}

/**
 * 取消事件订阅
 * @param {event} eventType 
 * @param {function} callback 
 * @param {HTMLElement} target 
 */
export function unSubscribeUI(eventType, callback, target = window) {
  if (target && eventType) {
    target.removeEventListener(eventType, callback)
  }
}

/**
 * 是否显示review
 * @param {object} data agent
 * @returns boolean
 */
export function showReview(data) {
  return !data.hideRaa && data.isPackage
}

/**
 * 预加载页面
 * @param {string} url 
 * @param {string} as 
 */
export async function preload(url, as = 'style') {
  if (!url) return
  const ReactDOM = (await import('react-dom')).default
  ReactDOM.preload(url, { as })
}

/**
 * stopPropagation
 * @param {event} event 
 */
export function stopPropagation(event) {
  event.stopPropagation()
}

/**
 * 链接字符串，去除无效数据
 * @param {array} list 
 * @param {string} separator 
 * @returns string
 */
export function joinStr(list, separator = ', ') {
  return join(filter(list, item => !isNull(item) && !isUndefined(item) && item !== ''), separator)
}

/**
 * 字符串转数字
 * @param {string} str 
 * @returns number
 */
export function toNumber(str) {
  return /^-?\d+(\.\d+)?$/.test(str) ? parseFloat(str) : str
}

/**
 * 根据house search type获取对应文本
 * @param {string} type houseSearchType
 * @param {boolean} isLandHome isLandHome
 * @returns {breadcrumbText}
 */
export function getHouseSearchTypeData({ type, isLandHome } = {}) {
  let breadcrumbText
  let h1Text
  let typeId
  switch (type) {
    case houseSearchType.residential:
      breadcrumbText = 'For Sale'
      h1Text = 'houses for sale'
      typeId = HOUSE_TYPE_ID.residential
      break
    case houseSearchType.rent:
      breadcrumbText = 'For Rent'
      h1Text = 'houses for rent'
      typeId = HOUSE_TYPE_ID.rent
      break
    case houseSearchType.sold:
      breadcrumbText = 'Sold'
      h1Text = 'houses sold'
      typeId = HOUSE_TYPE_ID.sold
      break
    case houseSearchType.rural:
      breadcrumbText = 'Rural for sale'
      h1Text = 'properties for sale'
      typeId = HOUSE_TYPE_ID.rural
      break
    case houseSearchType.commercialForSale:
      breadcrumbText = 'Commercial for sale'
      h1Text = 'commercial properties for sale'
      typeId = HOUSE_TYPE_ID['commercial-property-for-sale']
      break
    case houseSearchType.commercialForLease:
      breadcrumbText = 'Commercial for lease'
      h1Text = 'commercial properties for lease'
      typeId = HOUSE_TYPE_ID['commercial-property-for-lease']
      break
    case houseSearchType.business:
      breadcrumbText = 'Businesses for sale'
      h1Text = 'businesses for sale'
      typeId = HOUSE_TYPE_ID['businesses-for-sale']
      break
    case houseSearchType.newHomes:
      breadcrumbText = 'New Homes'
      h1Text = isLandHome ? 'house and land properties for sale' : 'apartments and terraces house for sale'
      typeId = HOUSE_TYPE_ID['new-home']
      break
  }
  return {
    breadcrumbText,
    h1Text,
    typeId
  }
}

/**
 * 根据house数据获取对应文本
 * @param {object} data 房源数据
 * @returns type, text
 */
export function getHouseTypeData(data) {
  let type
  let text

  if (isOfflineData(data)) {
    type = houseSearchType.sold
    text = 'Sold'
  } else if (isRentData(data)) {
    type = houseSearchType.rent
    text = 'For rent'
  } else if (isRuralData(data)) {
    type = houseSearchType.rural
    text = 'Rural'
  } else if (isCommercialForSaleData(data)) {
    type = houseSearchType.commercialForSale
    text = 'Commercial for sale'
  } else if (isCommercialForLeaseData(data)) {
    type = houseSearchType.commercialForLease
    text = 'Commercial for lease'
  } else if (isBusinessesForSaleData(data)) {
    type = houseSearchType.business
    text = 'Businesses for sale'
  } else if (isProjectData(data) || isNewhomeData(data)) {
    type = houseSearchType.newHomes
    text = 'New homes'
  } else {
    type = houseSearchType.residential
    text = isEstimateData(data) ? 'Estimate' : 'For sale'
  }
  return { type, text }
}

/**
 * priceFrom priceTo 转参数需要的price label
 * @param {number} priceFrom 
 * @param {number} priceTo 
 * @param {string} key 
 * @returns price
 */
export function getPriceQuery(priceFrom, priceTo, key) {
  let res
  if (priceFrom || priceTo) {
    if (priceFrom && priceTo) {
      res = `${priceFrom}-${priceTo}`
    } else if (priceFrom) {
      res = encodeURIComponent(`${priceFrom}+`)
    } else if (priceTo) {
      res = `${0}-${priceTo}`
    }
  } else {
    return res
  }
  return key ? { [key]: res } : res
}

/**
 * priceFrom priceTo 转页面显示的price label
 * @param {*} priceFrom 
 * @param {*} priceTo 
 * @returns label
 */
export function getPriceLabel(priceFrom, priceTo, priceToPrefix = 'Up to') {
  let res = 'Price'
  if (priceFrom || priceTo) {
    if (priceFrom && priceTo) {
      res = joinStr([toPrice(toNumber(priceFrom)), toPrice(toNumber(priceTo))], '-')
    } else if (priceFrom) {
      res = `${toPrice(toNumber(priceFrom))}+`
    } else if (priceTo) {
      res = `${priceToPrefix} ${toPrice(toNumber(priceTo))}`
    }
  }
  return res
}

/**
 * 面积字符串转数据
 * @param {string} label 0 - 50m²
 * @returns 0-50
 */
export function areaLableToNumber(label) {
  return toNumber(replace(toString(label), /[^0-9-]/g, ''))
}

/**
 * 把面积值转为houses/list能够支持的数据
 * @param {string} label 0-50,10000
 * @returns 0-50,10000+
 */
export function areaNumberToQuery(label) {
  if (!label) return
  return toString(label).includes('-') ? label : `${label}+`
}

/**
 * 获取带host的url
 * @param {string} pathname '/property/central-otago-lakes-district/lower-shotover/8-kahiwi-drive-koko-ridge/VECE4'
 * @returns https://test2next.ihomes.co.nz/property/central-otago-lakes-district/lower-shotover/8-kahiwi-drive-koko-ridge/VECE4
 */
export function getFullPath(pathname) {
  return [process.env.NEXT_PUBLIC_DOMAIN.replace(/\/+$/, ''), replace(pathname, /^\/+/, '')].join('/')
}
/**
 * 根据数据获取文字的复数形式
 * @param {number} number 
 * @param {string} label 
 * @param {string} plural 
 * @returns plural
 */
export function getLabelPlural(number, label, plural = 's') {
  return number > 1 ? `${label}${plural}` : label
}

/**
 * 房源数据同一坐标合并
 * @param {Array} data
 * @returns points
 */
export function combinePoints(data) {
  const res = []
  const lnglatMap = {}
  each(data, item => {
    const key = joinStr([item.lng, item.lat], '-')
    const list = get(lnglatMap, key, [])
    list.push(item)
    lnglatMap[key] = sortBy(list, 'typeId')
  })

  each(lnglatMap, val => {
    const temp = first(val)
    if (isPlainObject(temp)) {
      temp.combineIds = map(val, item => item.id)
      temp.combineTypeIds = map(val, item => [item.id, item.typeId])
      res.push(temp)
    }
  })
  return res
}

/**
 * 号码遮挡
 * @param {number|string} number 
 * @returns number 
 */
export function blockNumber(number) {
  if (!number) return number
  return replace(number, /.{4}$/, '...')
}

/**
 * 是否邮件地址
 * @param {string} text 
 * @returns boolean
 */
export function isEmail(text) {
  return /[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\.[a-zA-Z]{2,4}/.test(text)
}

/**
 * 把object key由下划线转成驼峰
 * @param {object} data 
 * @returns object
 */
export function object2camelCase(data) {
  if (isArray(data)) {
    return map(data, item => {
      return object2camelCase(item)
    })
  } else if (isPlainObject(data)) {
    let res = {}
    each(data, (val, key) => {
      res[camelCase(key)] = object2camelCase(val)
    })
    return res
  } else {
    return data
  }
}

/**
 * string query转query object
 * houses/list?rect=174.91905354209217,175.86662434287393,-38.559310904980016,-37.493795542874224&regionId=36&typeId=1&offset=0&limit=40&getPoint=1
 * 转成
 * {rect: "174.91905354209217,175.86662434287393,-38.559310904980016,-37.493795542874224", regionId: "36", typeId: "1", offset: "0", limit: "40", getPoint: "1"}
 * @param {string} str
 * @returns object
 */
export function string2query(str) {
  if (!str) return
  const res = {}
  const arr = str.substring(str.indexOf('?') + 1).split('&')
  each(arr, item => {
    const [key, val] = item.split('=')
    res[key] = val
  })
  return res
}

/**
 * 替换对象值中的变量
 * @param {object} data {message: 'hello, {name}', estimate_search: '{search|select to search|deselect to search}'}
 * @param {object} map {name: 'world', search: true}
 * @returns object {message: 'hello, world'}
 * @example 
 * `
 * const data = {message: 'hello, {name}', estimate_search: '{search|select to search|deselect to search}'}
 * replaceObjectVariable(data, {name: 'world', search: true}) // {message: 'hello, world', estimate_search: 'select to search'}
 * replaceObjectVariable(data, {name: 'world', search: false}) // {message: 'hello, world', estimate_search: 'deselect to search'}
 * `
 */
export function replaceObjectVariable(data, map) {
  if (!map) return data

  return mapValues(data, val => {
    if (!val) return val
    let res = val
    let regIterator = val.matchAll(/\{([a-z]+)(\|([a-z\s]+)\|([a-z\s]+))?\}/gi)
    let next = regIterator.next()

    while (!next.done) {
      if (next.value[0]) {
        const [_, key, __, select, deselect] = next.value
        res = `${res || ''}`.replace(next.value[0], select && deselect ? (map[key] ? select : deselect) : map[key])
      }
      next = regIterator.next()
    }
    return res
  })
}

/**
 * 判断用户是否有pointing设备，比如鼠标
 * @returns boolean
 */
export function hasPointingDevice() {
  return typeof matchMedia === 'function' && matchMedia('(pointer:fine)').matches
}

/**
 * 获取colliers下面中介的样式
 * @param {object} office 
 * @returns className
 */
export function getColliersClassName(office) {
  if (!office) return ''

  const { branch, name } = office
  if (`${branch}`.toLocaleLowerCase().includes('colliers') || `${name}`.toLocaleLowerCase().includes('colliers')) {
    return 'child-[img]:object-center'
  }

  return ''
}

/**
 * 判断是否支持全屏
 * @returns boolean
 */
export function isFullscreenSupported() {
  return document.fullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.msFullscreenEnabled;
}


/**
* 当前是否全屏
*/
export function isFullscreen() {
  return document.fullscreenElement !== null
}

/**
 * 退出全屏
 */
export function exitFullscreen() {
  if (document.exitFullScreen) {
    document.exitFullScreen()
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  } else if (element.msExitFullscreen) {
    element.msExitFullscreen()
  }
}

/**
 * 全屏
 */
export function requestFullscreen(element) {
  if (element.requestFullScreen) {
    element.requestFullScreen()
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen()
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen()
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen()
  }
}

/**
 * 调用app方法
 * @param {string} name 
 * @param {object} params 
 */
export function callNative(name, params) {
  if (params instanceof Object) params = JSON.stringify(params)
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.nativeCallback) {
    window.webkit.messageHandlers.nativeCallback.postMessage([name, params ? Base64.encode(params) : ''])
  } else if (window.callBackFormJs && window.callBackFormJs[name]) {
    if (params) {
      console.log(Base64.encode(params))
      window.callBackFormJs[name](Base64.encode(params))
    } else {
      window.callBackFormJs[name]()
    }
  }
}

/**
 * 接收app方法
 * @param {string} name 
 * @returns promise
 */
export function receiveNative(name) {
  return new Promise((resolve) => {
    window[name] = function (data) {
      resolve(data ? JSON.parse(Base64.decode(data)) : undefined)
    }
  })
}

/**
 * 数组转范围
 * @param {Array} arr 
 * @returns 
 * console.log(convertToRanges([1, 2, 3, 4, 5]));   // 输出: "1-5"
 * console.log(convertToRanges([1, 2, 3, 4]));      // 输出: "1-4"
 * console.log(convertToRanges([1, 2, 3]));         // 输出: "1-3"
 * console.log(convertToRanges([2, 4, 3, 5]));       // 输出: "2-5"
 * console.log(convertToRanges([1, 2]));           // 输出: "1,2"
 * console.log(convertToRanges([1, 3]));           // 输出: "1,3"
 * console.log(convertToRanges([1, 3, 4, 5]));         // 输出: "1,3-5"
 */
export function convertToRanges(arr) {
  if (arr.length === 0) {
    return ''
  }

  if (size(arr) === 1 && arr[0] === 5) return '5+'

  arr.sort((a, b) => a - b)

  let result = ''
  let startRange = arr[0]
  let endRange = arr[0]

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] === endRange + 1) {
      endRange = arr[i]
    } else {
      result += (startRange === endRange) ? startRange + ',' : startRange + '-' + endRange + ','
      startRange = arr[i]
      endRange = arr[i]
    }
  }

  result += (startRange === endRange) ? startRange : startRange + '-' + endRange

  result = result.replace(/5/, '5+')

  return result
}

/**
 * 判断是否有值
 * @param {any} val 
 * @returns boolean
 */
export function isEmpty(val) {
  return val !== void 0 && val !== null && val !== ''
}

/**
 * 格式化视频地址
 * @param {string} url 
 * @returns url
 */
export function formatVideoUrl(url) {
  if (!url) return

  let res = url
  const youtube = url.match(/\/\/(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/|shorts\/)?([a-z0-9\-_%]+)/i)
  const vimeo = url.match(/\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i)
  const dailymotion = url.match(/\/\/(?:www\.)?dai.ly\/([0-9a-z\-_]+)/i)
  const vk = url.match(/\/\/(?:www\.)?(?:vk\.com|vkontakte\.ru)\/(?:video_ext\.php\?)(.*)/i)
  if (youtube && youtube.length > 1) {
    res = `//www.youtube.com/embed/${youtube[1]}?wmode=opaque&autoplay=1&enablejsapi=1`
  } else if (vimeo && vimeo.length > 1) {
    res = `//player.vimeo.com/video/${vimeo[1]}?autoplay=1&api=1`
  } else if (dailymotion && dailymotion.length > 1) {
    res = `//www.dailymotion.com/embed/video/${dailymotion[1]}?wmode=opaque&autoplay=1&api=postMessage`
  } else if (vk && vk.length > 1) {
    res = `http://vk.com/video_ext.php?${vk[1]}?autoplay=1`
  }

  // 如果没有https://或者http://，则加上https://
  if (!/^https?:\/\//.test(res)) {
    res = `https://${res}`
  }

  return res
}

/**
 * 页面错误回调
 */
export function pageErrorCallback(res) {
  const status = get(res, 'status', 0)
  if (status === 404) {
    notFound()
  } else {
    throw res.statusText
  }
}
/**
 * 合并多个类名，返回一个合并后的类名字符串
 * @param {...string} inputs - 要合并的类名
 * @returns {string} - 合并后的类名字符串
 */
export function cn(...inputs) {
  return twMerge(classNames(inputs))
}